import dynamic from 'next/dynamic';

export const AdvanceWarsTemplate = dynamic(
  () => import('./AdvanceWarsTemplate/advance-wars-switch')
);
export const AnimalCrossingNewHorizonsPage = dynamic(
  () => import('./AnimalCrossingTemplate/animal-crossing-new-horizons-switch')
);
export const AnotherCodeRecollectionSwitch = dynamic(
  () =>
    import('./AnotherCodeRecollectionTemplate/another-code-recollection-switch')
);
export const Bayonetta3Switch = dynamic(
  () => import('./Bayonetta3Template/bayonetta-3-switch')
);
export const BayonettaOriginsSwitch = dynamic(
  () => import('./BayonettaOriginsTemplate/bayonetta-origins-switch')
);
export const DetectivePikachuReturnsSwitch = dynamic(
  () =>
    import('./DetectivePikachuReturnsTemplate/detective-pikachu-returns-switch')
);

export const plmpStandard = dynamic(
  () => import('@local/components/pages/templates/plmp-standard/plmp-standard')
);
export const peon = dynamic(
  () => import('@local/components/pages/templates/peon/peon')
);
export const DisneyIllusionIslandSwitch = dynamic(
  () => import('./DisneyIllusionIslandTemplate/disney-illusion-island-switch')
);
export const EndlessOceanLuminousSwitch = dynamic(
  () => import('./EndlessOceanLuminousTemplate/endless-ocean-luminous-switch')
);
export const May2024Switch = dynamic(
  () => import('./May2024Template/may-2024-switch')
);
export const FZero99Switch = dynamic(
  () => import('./FZero99Template/f-zero-99-switch')
);
export const WarioWareMoveIt = dynamic(
  () => import('./WarioWareMoveItTemplate/warioware-switch')
);
export const EverybodySwitch = dynamic(
  () => import('./EverybodySwitchTemplate/everybody-switch')
);
export const GameBuilderGarageSwitchPage = dynamic(
  () => import('./GameBuilderGarageTemplate/game-builder-garage-switch')
);
export const GameAndWatchLegendOfZeldaPage = dynamic(
  () =>
    import(
      './GameAndWatchLegendOfZeldaTemplate/game-watch-the-legend-of-zelda-115160'
    )
);
export const KirbyStarAlliesSwitchPage = dynamic(
  () => import('./KirbyStarAlliesTemplate/kirby-star-allies-switch')
);
export const LiveALiveSwitch = dynamic(
  () => import('./LiveALiveTemplate/live-a-live-switch')
);
export const MarioVsDk = dynamic(
  () => import('./MarioVsDkTemplate/mario-vs-dk-switch')
);

export const MinecraftTemplate = dynamic(
  () => import('./MinecraftTemplate/minecraft-switch')
);
export const ShipS = dynamic(
  () =>
    import('./MetroidPrimeRemasteredTemplate/metroid-prime-remastered-switch')
);
export const MiitopiaPage = dynamic(
  () => import('./MiitopiaTemplate/miitopia-switch')
);
export const PokemonLegendsArceusPage = dynamic(
  () => import('./PokemonLegendsArceusTemplate/pokemon-legends-arceus-switch')
);
export const Tetris99SwitchPage = dynamic(
  () => import('./Tetris99Template/tetris-99-switch')
);
export const WarioWareGetItTogetherSwitch = dynamic(
  () =>
    import('./WariowareGetItTogetherTemplate/warioware-get-it-together-switch')
);
export const TriangleStrategySwitchPage = dynamic(
  () => import('./TriangleStrategyTemplate/TriangleStrategySwitchPage')
);
export const NintendoEntertainmentSystemPage = dynamic(
  () =>
    import(
      './NintendoEntertainmentSystemTemplate/nintendo-entertainment-system'
    )
);
export const SuperNintendoEntertainmentSystemPage = dynamic(
  () =>
    import(
      './SuperNintendoEntertainmentSystemTemplate/super-nintendo-entertainment-system'
    )
);
export const SwitchHardwareTemplate = dynamic(
  () => import('./SwitchHardwareTemplate/switch-hardware-template')
);
export const Nintendo64Page = dynamic(
  () => import('./Nintendo64Template/nintendo-64')
);
export const SegaGenesisPage = dynamic(
  () => import('./SegaGenesisTemplate/sega-genesis')
);
export const MarioStrikersSwitch = dynamic(
  () => import('./MarioStrikersTemplate/mario-strikers-switch')
);
export const FireEmblemThreeHopes = dynamic(
  () => import('./FireEmblemThreeHopesTemplate/fire-emblem-three-hopes-switch')
);
export const XenobladeChronicles3Switch = dynamic(
  () => import('./XenobladeChronicles3Template/xenoblade-chronicles3-switch')
);
export const KirbysDreamBuffetSwitch = dynamic(
  () => import('./KirbysDreamBuffetSwitch/kirbys-dream-buffet-switch')
);
export const PokemonScarletSwitch = dynamic(
  () => import('./PokemonScarletSwitch/PokemonScarletSwitch')
);
export const PokemonVioletSwitch = dynamic(
  () => import('./PokemonVioletSwitch/PokemonVioletSwitch')
);
export const WDEV4389 = dynamic(
  () => import('./WDEV4389Template/wdev-4389-product')
);
export const NintendoSwitchGameVouchers = dynamic(
  () => import('./NintendoSwitchGameVouchers/nintendo-switch-game-vouchers')
);
export const PokemonScarletVioletDLCTemplate = dynamic(
  () =>
    import('./PokemonScarletVioletDLCTemplate/PokemonScarletVioletDLCTemplate')
);
export const PokemonScarletVioletDoublePackSwitch = dynamic(
  () =>
    import(
      './PokemonScarletVioletDoublePackSwitch/PokemonScarletVioletDoublePackSwitch'
    )
);

export const TearsOfTheKingdomTemplate = dynamic(
  () => import('./TearsOfTheKingdomTemplate/tears-of-the-kingdom-switch')
);

export const FireEmblemEngageSwitch = dynamic(
  () => import('./FireEmblemEngageTemplate/fire-emblem-engage-switch')
);
export const KirbysReturnToDreamLandDeluxe = dynamic(
  () =>
    import(
      './KirbysReturnToDreamLandDeluxe/kirbys-return-to-dream-land-deluxe-switch'
    )
);
export const LClassicsPage = dynamic(
  () => import('./LClassicsTemplate/l-classics')
);

/**
 * External package PMPs
 */
export const Pikmin4 = dynamic(
  () => import('@nintendo-of-america/marketing/dist/es/PMP/Pikmin4')
);
export const SMBWonder = dynamic(
  () => import('@nintendo-of-america/marketing/dist/es/PMP/SMBWonder')
);
export const SuperMarioRPG = dynamic(
  () => import('@nintendo-of-america/marketing/dist/es/PMP/SuperMarioRPG')
);
export const PrincessPeachShowtime = dynamic(
  () =>
    import('@nintendo-of-america/marketing/dist/es/PMP/PrincessPeachShowtime')
);
export const PaperMarioTTYD = dynamic(
  () => import('@nintendo-of-america/marketing/dist/es/PMP/PaperMarioTTYD')
);
export const LuigisMansion2HD = dynamic(
  () => import('@nintendo-of-america/marketing/dist/es/PMP/LuigisMansion2HD')
);
export const LegendOfZeldaEOW = dynamic(
  () =>
    import(
      '@nintendo-of-america/marketing/dist/es/PMP/LegendOfZeldaEOW_PhaseTwo'
    )
);
export const SuperMarioPartyJamboree = dynamic(
  () =>
    import('@nintendo-of-america/marketing/dist/es/PMP/SuperMarioPartyJamboree')
);
export const MarioLuigiBrothership = dynamic(
  () =>
    import(
      '@nintendo-of-america/marketing/dist/es/PMP/SuperMarioLuigiBrothership'
    ),
  { ssr: false }
);
